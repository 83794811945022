#categories-selector {
  margin: 25px auto 10px;
}

#categories-selector a {
  all: unset;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  margin: 0 10px 0;
  padding-bottom: 2px;
}

#categories-selector a.selected {
  color: #6029a1;
  border-bottom: 2px solid #6029a1;
}

#blog-cards-page-container {
  display: flex;
  flex-direction: column;
}

#blog-search-bar {
  all: unset;
  padding: 15px;
  outline: 1px solid black;
  border-radius: 100px;
  margin: 20px 300px 20px;
}

#blog-cards-categories {
  margin: 0 auto;
}

.category-header {
  color: #6029a1;
  display: flex;
  align-items: center;
}

.category-header a {
  margin-left: 10px;
}

#blog-cards-search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 100px;
}

.blog-cards-row {
  display: flex;
}

#blog-search-bar:focus {
  outline: 2px solid #6029a1;
}

.card-body {
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 15px 15px 10px;
  margin: 15px;
  cursor: pointer;
  width: 250px;
  height: 400px;
  border: 2px solid #6029a1;

  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.blog-img {
  border-radius: 10px;
  width: 100%;
  height: fit-content;
}

.card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  overflow: hidden;
  height: min-content;
  margin-bottom: 0;
  display: table;
}

.card-body:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.05);

  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

#blog-card-content {
  margin-top: 0;
}

.card-date {
  margin: 10px 0;
}
