#welcome {
  position: absolute;
  right: 20px;
}

#info-container {
  margin-top: 20px;
}

#create-account {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: min-content;
  margin: auto;
  white-space: nowrap;
}

#create-account h1 {
  margin-top: 50px;
  text-align: center;
}

#create-account #info-container {
  display: flex;
}

#info-container form {
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}

#info-container input,
#info-container textarea {
  all: unset;
  padding: 15px;
  outline: 1px solid black;
  border-radius: 5px;
  width: 300px;
  margin-bottom: 28px;
}

#info-container input:disabled {
  color: gray;
}

#info-container input[type="text"]:focus,
#info-container textarea:focus {
  outline: 2px solid #6029a1;
}

#info-container input[type="text"],
#info-container textarea {
  background-color: #f0f2f4;
}

/* Chrome, Safari, Edge, Opera */
#info-container input[type="number"]::-webkit-outer-spin-button,
#info-container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#info-container input[type="number"] {
  -moz-appearance: textfield;
}

#info-container label {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 500;
}

#bday-input input {
  width: 40px;
  text-align: center;
  margin-right: 10px;
}

#bday-input #byear {
  width: 50px;
}

#socials-select,
#gender-selector {
  display: flex;
  margin-bottom: 28px;
}

#socials-select p,
#gender-selector p {
  margin: 0 10px 0 0;
  padding: 15px;
  background-color: #f0f2f4;
  border-radius: 5px;
  outline: 1px solid black;
}

#socials-select .selected,
#gender-selector .selected {
  outline: 2px solid #6029a1;
  color: #6029a1;
}

#photo-insert-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

#photo-selector-container {
  height: min-content;
}

#photo-selector-container p {
  text-align: center;
}

.photo-insert-grid-item {
  width: 150px;
  height: 150px;
  line-height: 150px;
  margin: 4px !important;
  background-color: #e9ebee;
  border-radius: 8px;
  border: 3px dashed gray;
  color: gray;
  font-size: 50px;
  cursor: pointer;
  text-align: center;
  transition-duration: 0.2s;
}

.photo-insert-grid-item:hover {
  transform: scale(1.05);
  transition-duration: 0.2s;
}

.photo-insert-grid-item input[type="file"] {
  display: none !important;
}

.photo-insert-grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.photo-insert-grid-item img:hover {
  opacity: 0.3;
}

#photo-edit-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

#photo-edit-modal h1 {
  margin: 0;
  font-weight: 500;
}

#photo-edit-modal p {
  margin: 5px 0 20px;
}

#photo-edit-modal .ReactCrop {
  max-width: 400px;
  max-height: 400px;
  border: 2px solid gray;
  border-radius: 10px;
}

#photo-edit-modal #buttons-row {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
}

#photo-edit-modal #buttons-row button {
  padding: 15px 50px;
  border-radius: 5px;
  border: 1px solid gray;
  background-color: #6029a1;
  color: white;
  cursor: pointer;
}

#photo-edit-modal #buttons-row button:disabled,
#continue-button:disabled {
  background-color: hsl(0, 0%, 70%);
  color: #e6e6e6;
}

#photo-edit-modal #buttons-row button:hover:enabled,
#continue-button:hover:enabled {
  background-color: #7f37d0;
}

#photo-edit-modal #buttons-row button:active:enabled,
#continue-button:active:enabled {
  background-color: #6029a1;
}

#invalid-date {
  color: red;
  margin: -15px 0 20px;
}

#continue-button {
  padding: 15px 50px;
  border-radius: 50px;
  border: 1px solid gray;
  background-color: #6029a1;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin: 0 auto 20px;
}

#info-container #right-box {
  display: flex;
  flex-direction: column;
}

#right-box textarea {
  width: 100%;
  height: 200px;
  white-space: pre-wrap;
}

#tags-box {
  background-color: #f0f2f4;
  padding: 15px;
  outline: 1px solid black;
  border-radius: 5px;
  margin: -10px 0 28px 0;
  display: flex;
  flex-wrap: wrap;
  width: 300px;
}

.user-tag {
  padding: 5px;
  background-color: #e3e3e3;
  margin: 5px;
  outline: 1px solid #d1d1d1;
  border-radius: 5px;
  display: flex;
  height: min-content;
}

.user-tag:hover {
  outline: 2px solid #6029a1;
  background-color: #d1d1d1;
}

.user-tag p {
  margin: 0;
}

.remove-tag-button {
  font-family: cursive;
  transform: translateY(-1px);
  color: #818181;
  padding: 0 4px;
  border-radius: 4px;
  background-color: #d3d3d3;
  outline: 1px solid #838383;
  margin-left: 8px;
}

.remove-tag-button:hover {
  background-color: #777777;
  color: white;
  cursor: pointer;
}

#submit-tag-button {
  color: gray;
  font-size: 40px;
  margin: 0;
  transform: translate(-34px, -3px);
}

#submit-tag-button:hover {
  cursor: pointer;
  color: #6029a1;
}

#tag-selection-box {
  background-color: #f0f2f4;
  padding: 15px;
  outline: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 150px;
  overflow-y: scroll;
  margin-bottom: 28px;
}
