#contact-us-container {
  margin: 50px 0 100px 15%;
  width: min-content;
  display: flex;
}

#contact-us-container h1 {
  text-wrap: nowrap;
}

#contact-us-container form {
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #6029a1;
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}

#contact-us-container img {
  height: min-content;
  margin-top: 100px;
}

#contact-us-container input,
#contact-us-container textarea {
  all: unset;
  padding: 15px;
  outline: 1px solid black;
  border-radius: 5px;
  width: 300px;
  margin: 10px 0 28px;
  background-color: #f0f2f4;
}

#contact-us-container input:focus,
#contact-us-container textarea:focus {
  outline: 2px solid #6029a1;
}

#contact-us-container textarea {
  height: 200px;
  width: 400px;
}

#contact-us-container button {
  padding: 14px 40px;
  border-radius: 50px;
  border: 1px solid gray;
  background-color: #6029a1;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

#contact-us-container button:hover {
  background-color: #7f37d0;
}

#contact-us-container button:active {
  background-color: #6029a1;
}

#user-report-info {
  color: #6029a1;
  margin: 5px 0;
}
