body {
  font-family: Inter, sans-serif;
  margin: 0;
}

#profile-page-container {
  display: flex;
}

#profile-page {
  width: min-content;
  margin: auto;
}

#profile-page h1 {
  margin-bottom: 0;
}

#profile-page hr {
  margin: 16px 0 0;
}

#profile-banner p {
  margin: 0 16px 0 0;
  color: #8f8f8f;
}

#profile-banner {
  display: flex;
  margin-bottom: 8px;
}

#profile-socials .socials-box {
  background-color: white;
  margin-right: 10px;
}

#tags-box-profile .socials-box {
  background-color: white;
}

#profile-socials .socials-container {
  margin: 0;
  display: flex;
  margin-bottom: 8px;
}

.swiper {
  width: 500px;
  height: 500px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#image-swiper-container {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #bcbcbc;

  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

#image-swiper-container:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: scale(1.025);

  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

#gallery-contacts {
  display: flex;
}

#tags-box-profile {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 15px 15px 10px;
  margin: 20px 0 0 20px;
  width: 250px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #bcbcbc;
  overflow-y: scroll;
  height: 420px;
}

#carousel-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#report-button {
  all: unset;
  margin: 10px auto;
  padding: 10px 15px;
  border-radius: 50px;
  border: 1px solid gray;
  background-color: #f3f3f3;
  cursor: pointer;
  font-weight: bold;
}

#report-button:hover {
  background-color: #cacaca;
}

#report-button:active {
  background-color: #a5a5a5;
}

#bio-box {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border: 1px solid #bcbcbc;
  margin: 20px 0;
}

#bio-box h2 {
  margin-top: 0;
}

#bio-box p {
  margin-bottom: 0;
}

#tags-box-profile .socials-box {
  padding: 15px 50px;
  background-color: #7f37d0;
  color: white;
  border-radius: 50px;
  font-weight: bold;
}

#tags-label {
  margin: 10px 80% 5px 0;
  font-weight: bold;
}
