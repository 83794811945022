#menu li svg {
  height: 30px;
  width: 30px;
  margin-right: 20px;
  fill: rgb(76, 76, 76);
}

#menu li {
  all: unset;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  color: rgb(76, 76, 76);
  cursor: pointer;
  padding: 5px 30px;
  border-radius: 20px;
}

#menu li:hover {
  color: #6029a1;
  background-color: #f5f1f8;
}

#menu li:hover svg {
  fill: #6029a1;
}

#menu {
  border-right: 2px solid #6029a1;
  border-bottom: 2px solid #6029a1;
  padding: 30px 10px 0 30px;
  margin: 0;
  border-radius: 10px;
}
