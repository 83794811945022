#chat-nav {
  width: 30%;
  height: 100%;
  border-right: 1px solid #a5a5a5;
}

#chat-nav #chat-nav-header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #a5a5a5;
  height: 50px;
  align-items: center;
  justify-content: space-between;
}

#chat-nav #chat-nav-header h1 {
  font-size: 25px;
  margin: 0 0 0 10px;
}

#chat-nav #chat-nav-header button {
  all: unset;
  margin-right: 10px;
  font-size: 40px;
  line-height: 22px;
  border-radius: 100%;
  padding: 4px 3px 7px;
  font-weight: 200;
  transition-duration: 0.2s;
}

#chat-nav #chat-nav-header button:hover {
  color: #0091ea;
  background-color: #f0f9fe;
  font-weight: 300;
}

#chat-nav #chat-nav-header button:active {
  background-color: #bfe9ff;
}

#chat-nav #chat-nav-header button.selected {
  transform: rotate(45deg);
  transition-duration: 0.2s;
}

#chat-nav .chat-selector {
  display: flex;
  height: min-content;
  border-bottom: 1px solid #a5a5a5;
  padding: 10px;
}

#chat-nav .chat-selector:hover {
  background-color: #e5e5e5;
}

#chat-nav .chat-selector:active {
  background-color: #d5d5d5;
}

#chat-nav .chat-selector img {
  width: 75px;
  height: 75px;
  border-radius: 20px;
  border: 1px solid black;
}

.chat-text-display {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  overflow: hidden;
}

.chat-text-display .names {
  display: flex;
}

.chat-text-display .names h1 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  display: inline-block;
}

.chat-text-display .names p {
  font-size: 17px;
  font-weight: 400;
  margin: 0 0 0 5px;
  color: gray;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-text-display .recent-msg {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: gray;
  margin: 8px 0 0 0;
}

.msg-timestamp {
  color: gray;
  margin: 0;
}

#messages-search {
  width: 100%;
  height: 65px;
  background-color: #dedede;
  border-bottom: 1px solid #a5a5a5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: gray;
}

#messages-search input {
  all: unset;
  width: 100%;
}

#messages-search i {
  font-size: 30px;
  margin: 0 10px;
  cursor: pointer;
}

#no-results {
  margin: 10px auto;
  font-weight: 500;
  width: fit-content;
}

#messages-search-results .chat-selector .chat-text-display .names {
  flex-direction: column;
}

#messages-search-results .chat-selector .chat-text-display .names p {
  margin: 0;
}
