.profile-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 15px 15px 10px;
  margin: 15px;
  cursor: pointer;
  width: 250px;
  height: min-content;
  border: 2px solid #6029a1;
  background-color: white;

  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.profile-card #profile-image {
  border-radius: 10px;
  width: 100%;
}

.profile-card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.05);
  background: linear-gradient(to right bottom, #6029a1, #8b4bd4);

  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.profile-card:hover p,
.profile-card:hover h3 {
  color: white;
}

.profile-card p {
  color: #8f8f8f;
  margin: 3px 0;
}

.profile-card h3 {
  margin: 10px 0 5px;
}

#profile-cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: min-content;
  margin: 0 auto;
}

.socials-box {
  display: flex;
  height: 30px;
  width: min-content;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  user-select: none;
}

.socials-box:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.socials-box:active {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.socials-box img {
  height: 100%;
  margin-right: 5px;
}

.socials-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

#profile-cards-container-container {
  display: flex;
  height: min-content;
}

.profile-card .socials-box {
  margin-right: 10px;
}
