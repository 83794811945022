#nav-bar {
  display: flex;
  height: 13vh;
  align-items: center;
  border-bottom: 2px solid #6029a1;
  margin: 0 100px;
}

#nav-bar #logo {
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#nav-bar #logo h1 {
  margin-left: 10px;
  color: #6029a1;
}

#navbar-links {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 100%;
  margin: 0 30px;
}

#navbar-links a {
  all: unset;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  width: 100%;
  line-height: 100px;
  font-size: 18px;
  padding: 0 10px;
}

#navbar-links a:hover {
  background-color: #6029a1;
  color: white;
}

#account-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#account-indicator p {
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: 500px;
}

#account-indicator button {
  padding: 14px 40px;
  border-radius: 50px;
  border: 1px solid gray;
  background-color: #6029a1;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

#account-indicator button:hover {
  background-color: #7f37d0;
}

#account-indicator button:active {
  background-color: #6029a1;
}

#filters-button {
  font-size: 30px;
  margin: auto 10px;
}

#search-form-expansion {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  transition-duration: 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#search-form-expansion.expanded {
  width: 344px;
  height: 300px;
  z-index: 100000;
  border: 2px solid #6029a1;
}

.search-input {
  all: unset;
  padding: 0 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #dddddd;
  margin: 10px;
}

.search-input:focus {
  border: 2px solid #6029a1;
}

#search-bar {
  height: 50px;
  width: -webkit-fill-available;
  border-radius: 15px;
}

#search-form {
  position: relative;
  margin: auto 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #6029a1;
  border-radius: 20px;
  background-color: white;
}

#search-form-banner {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
  width: 320px;
}

#search-form-banner p {
  font-weight: bold;
  margin: 0;
  padding: 0 10px;
  border-right: 2px solid #dddddd;
  user-select: none;
}

#search-form-banner i {
  margin-left: 12px;
  font-size: 23px;
}

#filters-header > * {
  margin: 0 0 0 10px;
}

#filters-header {
  display: flex;
}

#filters-header i {
  color: #6029a1;
}

#search-form-expansion button {
  padding: 0 20px;
  border-radius: 100px;
  border: 1px solid gray;
  background-color: #6029a1;
  color: white;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
  height: 30px;
  margin: auto;
}

#search-form-expansion button:hover {
  color: #7f37d0;
}

#filters {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

#filters input[type="text"] {
  height: 30px;
  border-radius: 10px;
  width: 110px;
}

#filters input[type="range"] {
  width: 75%;
  margin-left: 10px;
}

.range-slider {
  display: flex;
  align-items: center;
  margin: 15px 0 10px 10px;
}

.range-slider p {
  margin: 0;
  font-weight: bold;
}

#gender-selector-search {
  display: flex;
}

#gender-selector-search p {
  margin: 0 10px 0 0;
  height: min-content;
  padding: 5px;
  background-color: #f0f2f4;
  border-radius: 5px;
  outline: 1px solid black;
}

#gender-selector-search .selected {
  outline: 2px solid #6029a1;
  color: #6029a1;
}

.filters-row {
  display: flex;
  align-items: center;
}

.links {
  width: 25%;
  margin: auto;
  display: flex;
  justify-content: space-around;
}

.links a {
  all: unset;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 20px 5px;
}

.links a:hover {
  color: #6029a1;
}
