#messages-container {
  display: flex;
  height: 87vh;
}

#chat-container {
  display: flex;
  width: 100%;
  height: 100%;
  margin-right: 100px;
  border-right: 1px solid #a5a5a5;
}
