#login-container {
  height: 100vh;
  display: flex;
  background: linear-gradient(to right bottom, #491f7b, #8238da);
}

#actions-container {
  margin: 100px auto;
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  height: min-content;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 340px;
  width: 570px;
}

#actions-container h1 {
  margin: 0 0 10px;
}

#actions-container #subtext {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 40px;
}

#actions-container #signin-button {
  all: unset;
  cursor: pointer;
  font-size: 20px;
  padding: 10px 30px;
  border-radius: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  border: 1px solid black;
  width: -webkit-fill-available;
  justify-content: center;
  margin-top: 15px;
}

#actions-container #signin-button img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

#actions-container p {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

#actions-container #signin-container {
  display: flex;
  margin: auto;
  white-space: nowrap;
  width: min-content;
  margin-top: 20px;
}

#signin-container #signin-p {
  color: rgb(25, 137, 207);
  font-weight: 500;
  margin-left: 7px;
  cursor: pointer;
}

#actions-container form {
  display: flex;
  flex-direction: column;
}

#actions-container form #names {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#actions-container input {
  all: unset;
  padding: 10px;
  outline: 1px solid black;
  border-radius: 5px;
}

#actions-container input[type="submit"] {
  width: min-content;
  margin: 30px auto 0;
  padding: 10px 40px;
}

#actions-container input[type="submit"]:hover {
  background-color: #7f37d0;
  color: white;
}

#actions-container input[type="submit"]:active {
  background-color: #6029a1;
  color: white;
}

#actions-container input[type="text"]:focus,
#actions-container input[type="password"]:focus {
  outline: 2px solid #6029a1;
}

#actions-container input[type="text"],
#actions-container input[type="password"] {
  background-color: #f0f2f4;
}

#actions-container label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 500;
}

#back-button {
  cursor: pointer;
  margin-bottom: 20px !important;
  width: min-content;
  white-space: nowrap;
}

.input-container {
  width: min-content;
}

#email-header {
  margin-bottom: 20px !important;
}

#passwords {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

#signin-actionsbox {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

#signin-actionsbox .input-container {
  width: 300px;
  display: flex;
  flex-direction: column;
}

#error-text {
  color: red;
  margin: 10px 0 -10px !important;
  text-align: center;
}

#forgot-pass {
  margin-top: 15px !important;
  color: rgb(25, 137, 207);
  font-weight: 400 !important;
  font-size: 16px !important;
  cursor: pointer;
}

.forgot-pass {
  margin-top: 40px !important;
}

#remember-box {
  display: flex;
  align-items: center;
  margin-top: 15px;
  user-select: none;
}

#remember-box label {
  margin: 0 0 0 5px !important;
}

#remember-box input:checked {
  background-color: #7f37d0;
}
