#notfound-container {
  display: flex;
  margin: 100px auto;
  align-items: center;
  width: 60%;
}

#notfound-container img {
  width: 45%;
}

#notfound-container h1 {
  text-wrap: nowrap;
}

#notfound-container button {
  padding: 14px 40px;
  border-radius: 50px;
  border: 1px solid gray;
  background-color: #6029a1;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

#notfound-container div {
  height: min-content;
  margin-right: 25px;
}

#notfound-container button:hover {
  background-color: #7f37d0;
}

#notfound-container button:active {
  background-color: #6029a1;
}
