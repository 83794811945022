#footer {
  background-color: #f3f3f3;
  padding: 30px 20px;
  border-top: 2px solid #6029a1;
  transform: translateY(-2px);
}

#footer-content {
  display: flex;
  padding-bottom: 20px;
}

#footer a {
  all: unset;
  cursor: pointer;
  margin-top: 10px;
  font-weight: bold;
  color: rgb(115, 117, 136);
}

#footer a:hover {
  color: #6029a1;
}

#copyright-statement {
  margin: 0 40px;
  color: #a7aaa8;
  text-align: center;
}

#footer-content > div {
  display: flex;
  flex-direction: column;
  width: 25%;
}

#footer-content > div:not(:last-child) {
  margin-right: 50px;
}

.footer-p {
  font-weight: bold;
  margin: 0;
  text-wrap: nowrap;
  color: #6029a1;
  font-size: 18px;
}

#footer svg {
  margin-left: -8px;
  cursor: pointer;
  margin-right: 10px;
}

#footer svg:hover path {
  fill: #6029a1;
}

#footer #logo {
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#footer #logo h1 {
  margin-left: 10px;
  color: #6029a1;
}

#footer-desc p {
  color: #6029a1;
  font-weight: bold;
}
