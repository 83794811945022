#discussion-container {
  height: 75%;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
}

#discussion-header {
  height: 10%;
  border-bottom: 1px solid #a5a5a5;
  display: flex;
  cursor: pointer;
}

#discussion-container::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

#discussion-container::-webkit-scrollbar-thumb {
  background-color: #555;
}

#discussion-header img {
  height: 85%;
  border-radius: 50%;
  border: 1px solid black;
  margin: auto 10px;
}

#discussion-header h1 {
  font-size: 25px;
  font-weight: 600;
  margin: auto 0;
}

#discussion-header p {
  font-size: 20px;
  font-weight: 500;
  margin: auto 10px;
  color: gray;
  text-overflow: ellipsis;
}

#chat-bar {
  height: 15%;
  border-top: 1px solid #a5a5a5;
  display: flex;
  justify-content: space-evenly;
}

#chat-bar textarea {
  margin: 15px 0;
  width: 75%;
  resize: none;
  font: inherit;
  outline: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  background-color: #f0f2f4;
}

#chat-bar textarea::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

#chat-bar textarea::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
}

#chat-bar button {
  all: unset;
  height: 50%;
  margin: auto 0;
  background-color: #6029a1;
  display: flex;
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border-radius: 40px;
  padding: 0 20px;
  font-size: 14px;
}

#chat-bar button:hover {
  background-color: #7f37d0;
}

#chat-bar button:active {
  background-color: #6029a1;
}

#chat-bar textarea:focus {
  outline: 2px solid #6029a1;
}

#chat-display {
  width: 70%;
  display: flex;
  flex-direction: column;
}

#chat-display #select-chat-filler {
  margin: auto;
  font-size: 20px;
  font-weight: 500;
}

.message {
  margin: 0 10px 10px;
}

.message .content {
  max-width: 60%;
  width: fit-content;
  overflow-wrap: break-word;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.075), 0px 7px 5px -5px rgba(0, 0, 0, 0.1);
}

.message.left .content {
  margin: 10px auto 10px 10px;
  border-radius: 20px 20px 20px 0;
  background-color: #e5e5e5;
}

.message.right .content {
  margin: 10px 10px 10px auto;
  border-radius: 20px 20px 0 20px;
  background-color: #e3effd;
}

.message .timestamp {
  font-size: 14px;
  color: #a5a5a5;
  width: min-content;
}

.message.right .timestamp {
  margin: 0 15px 0 auto;
}

.message.left .timestamp {
  margin: 0 auto 0 15px;
}

#start-chat {
  margin: auto;
  font-size: 20px;
  font-weight: 500;
}
