#hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right bottom, #491f7b, #8238da);
}

#hero-inner-container {
  display: flex;
  height: min-content;
  max-height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

#hero-logo {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 20px;
}

#hero-logo img {
  height: 100%;
  margin-right: 10px;
}

#hero-text {
  color: white;
  width: 50%;
}

#hero-text h2 {
  margin: 0;
  font-weight: 100;
}

#hero-text h1 {
  margin: 10px 0;
}

#hero-image {
  height: 500px;
  margin-left: 50px;
}

#hero button {
  padding: 15px 20px;
  margin-top: 20px;
  border: none;
  outline: none;
  color: #6029a1;
  background: linear-gradient(90deg, #fcecfe, #fbf6e7, #e6fcf5);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-weight: bold;
  font-size: 17px;
}

#hero button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #8a00ff,
    #7e18f1,
    #7228e3,
    #6637d5,
    #5a47c7,
    #4e57b9,
    #4266ab,
    #36769d,
    #8a00ff
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

#hero button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #fcecfe, #fbf6e7, #e6fcf5);
  left: 0;
  top: 0;
  border-radius: 10px;
}

#hero button:hover {
  color: #db93da;
}

#hero button:active {
  opacity: 0.8;
}

#hero i {
  color: white;
  margin: auto auto 1%;
  font-size: 50px;
}

.banner {
  background-color: #5e7cea;
  border-top: 2px solid #6029a1;
  border-bottom: 2px solid #6029a1;
}

.banner-item {
  display: flex;
  align-items: center;
  color: white;
  margin-right: 125px;
}

.banner-item h2 {
  margin-left: 10px;
}

.banner-item img {
  height: 50px;
}

#buyer-seller-desc {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

#buyer-seller-desc div {
  width: 30%;
  display: flex;
  flex-direction: column;
  border: 2px solid #6029a1;
  border-radius: 10px;
  margin: 50px 0;
  background-color: white;
  padding: 15px;
}

#buyer-seller-desc ul {
  padding-left: 16px;
  margin: 0;
}

#buyer-seller-desc ul li {
  margin: 10px 0;
}

#buyer-seller-desc h2 {
  margin-top: 0;
}

.divider {
  margin: 0 35px;
  border-top: 2px solid #6029a1;
}
